<template>
  <b-overlay :show="isProcessing[type]">
    <b-card body-class="p-0">
      <b-table
        responsive="sm"
        :items="items"
        :fields="fields"
        class="table-bordered mb-0"
        show-empty
      >
        <template #head()="data">
          <span class="text-capitalize">{{ data.label }}</span>
        </template>
        <template #cell(duration)="row">
          {{ CONVERT_HM(row.value) }}
        </template>
        <template #cell(name)="row">
          <span>
            <feather-icon
              v-b-tooltip.hover="CAPITALIZE(row.item.activityType)"
              :icon="row.item.activityType === 'WEBSITE' ? 'GlobeIcon': 'TrelloIcon'"
              size="20"
              style="margin-right: 4px; margin-bottom: 3px;"
            />

            {{ row.value }}
          </span>
        </template>
        <template #empty>
          <p class="text-center mb-0">
            {{ $t('No Data Found') }}
          </p>
        </template>
      </b-table>
    </b-card>
    <pagination
      v-if="items.length"
      :total-items="totalItems"
      :per-page="perPage"
      @onPaginationChange="(value) => $emit('onPaginationChange', value, type)"
    />
  </b-overlay>
</template>
<script>
import {
  BCard, BTable, BOverlay,
}
from 'bootstrap-vue'
import Pagination from '@/views/common/components/Pagination.vue'

export default {
  components: {
    BCard,
    BTable,
    Pagination,
    BOverlay,
  },
  props: {
    tableData: {
      type: Object,
      default: () => {},
    },
    perPage: {
      type: Number,
      default: 0,
    },
    isProcessing: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      totalItems: 0,
    }
  },
  computed: {
    bgColor() {
      switch (this.type) {
        case 'NEUTRAL':
          return 'bg-secondary text-white'
        case 'UNPRODUCTIVE':
          return 'bg-danger text-white'
        case 'PRODUCTIVE':
          return 'bg-success text-white'
        default:
          return 'bg-primary text-white'
      }
    },
    fields() {
      return [
        { key: 'name', label: this.$t('Application'), thClass: this.bgColor },
        { key: 'duration', label: this.$t('Duration'), thClass: this.bgColor },
      ]
    },
  },
  watch: {
    tableData() {
      this.items = this.tableData.data || []
      this.totalItems = this.tableData.total || 0
    },
  },
}
</script>
